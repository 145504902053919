<template>
	<v-sheet class="create-Customer create-page" id="customer" style="margin: 0 -12px">
		<div class="container">
			<v-row class="sticky">
				<v-col md="6" class="my-auto">
					<h1 class="custom-header-blue-text m-0">{{ pageTitle }}</h1>
				</v-col>
				<v-col md="6" class="text-right">
					<v-btn
						:loading="pageLoading"
						class="white--text mr-2"
						depressed
						color="blue darken-4"
						tile
						:disabled="pageLoading || !formValid"
						v-on:click="createOrUpdateExcel()"
					>
						Save
					</v-btn>
					<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
				</v-col>
			</v-row>
			<!-- v-model.trim="formValid" -->
			<v-form
				v-model.trim="formValid"
				ref="customer_submit"
				lazy-validation
				v-on:submit.stop.prevent="createOrUpdateExcel"
			>
				<v-row>
					<v-col cols="9">
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Industry Type</label>
							</v-col>
							<!-- :readonly="contract_id > 0 || projectId > 0" -->
							<v-col lg="8" class="my-auto py-0">
								<TextInput
									hide-details
									outlined
									placeholder="Industry Type"
									v-model="excelListing.indType"
									:rules="[vrules.required(excelListing.indType, 'Industry Type')]"
									:class="{
										required: !excelListing.indType,
									}"
								>
								</TextInput>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Software Type</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<TextInput
									hide-details
									outlined
									placeholder="Software Type"
									v-model="excelListing.softType"
									:rules="[vrules.required(excelListing.softType, 'Software Type')]"
									:class="{
										required: !excelListing.softType,
									}"
								>
								</TextInput>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Excel</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-file-input
									class="mt-0"
									hide-details
									v-model="excelListing.excel"
									color="blue darken-4"
									placeholder="Select your files"
									prepend-icon="mdi-file-excel"
									outlined
								></v-file-input>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-form>
		</div>
	</v-sheet>
</template>

<script>
import TextInput from "@/view/components/TextInput";
import { mapGetters } from "vuex";
import { POST_EXCELDATA } from "@/core/services/store/excel.module";
import { SET_ERROR } from "@/core/services/store/common.module";
import MainMixin from "@/core/mixins/main.mixin.js";
export default {
	name: "Create-Excel",
	mixins: [MainMixin],
	data() {
		return {
			pageTitle: "Create New Excel File",
			formValid: true,
			pageLoading: false,
			excelListing: {
				indType: "",
				softType: "",
				excel: null,
				pdf: null,
			},
		};
	},
	methods: {
		createOrUpdateExcel() {
			const formErrors = this.validateForm(this.$refs["customer_submit"]);
			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}
			this.pageLoading = true;
			this.$store
				.dispatch(POST_EXCELDATA, this.excelListing)
				.then((data) => {
					console.log("data", data);
					this.$router.push({ name: "excel" });
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
			//console.log("data", this.excelListing);
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
	components: {
		TextInput,
	},
};
</script>
